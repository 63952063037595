.extra-filter-container {
  position: absolute;
  top: 50%;
  right: 0;
  background-color: white;
  color: #121a8c;
  padding: 0.5rem;
  font-weight: bolder;
  font-size: 1.2rem;
  border-radius: 10px 0px 0px 10px;
  border: 2px solid #121a8c;
  border-right: 0px;
}

.extra-filter-container:hover {
  position: absolute;
  top: 50%;
  right: 0;
  background-color: rgba(206, 42, 39, 0.1);
  color: #ce2a27;
  padding: 0.5rem;
  font-weight: bolder;
  font-size: 1.6rem;
  border-radius: 10px 0px 0px 10px;
  border: 2px solid #ce2a27;
  border-right: 0px;
}
