.menu-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu-list > div {
  padding: 1rem;
  margin: 0.5rem;
  border: 2px solid #121a8c;
  border-radius: 1rem;
  background: rgba(18, 26, 140, 0.3);
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  text-align: center;
}
.menu-list > div:hover {
  border: 4px solid #ce2a27;
  border-radius: 1rem;
  background: rgba(206, 42, 39, 0.5);
  color: white;
  font-weight: bolder;
  font-size: 1.6rem;
  text-align: center;
}
