@media (min-width: 769px) {
  .detail-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .detail-container > .exit {
    display: none;
  }
  .detail-view {
    position: relative;
    padding: 1rem;
    background-color: white;
    box-shadow: 0.2rem 0.15rem 0px 0px rgba(0, 0, 0, 0.5);
    width: 80%;
    height: 90%;
    border-radius: 1.5rem;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
  .detail-image {
    height: 30%;
    max-height: 400px;
    display: flex;
    justify-content: center;
  }
  .detail-image > img {
    height: 100%;
  }
}
@media (max-width: 768px) {
  .detail-container {
    position: fixed;
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background-color: white;
  }
  .exit {
    position: absolute;
    top: 2.5%;
    left: 2.5%;
    width: 10%;
    z-index: 300;
  }
  .exit > img {
    width: 100%;
    object-fit: contain;
  }
  .detail-view {
    position: relative;
    overflow-y: scroll;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .detail-image {
    height: 30%;
    display: flex;
    justify-content: center;
  }
  .detail-image > img {
    height: 100%;
  }
  .detail-content {
  }
  .detail-title {
    padding: 0.5rem;
    color: #3e3e3e;
    font-weight: bold;
  }
  .detail-descriere {
    padding: 0.5rem;
    text-align: justify;
    font-family: arial;
  }
  .detail-list {
    padding: 0.5rem;
    display: flex;
    font-family: arial, sans-serif;
    font-size: auto;
    flex-direction: column;
  }

  .detail-list > div > div {
    text-align: left;
    padding: 8px;
  }
  .detail-list > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .detail-list > div {
    border-bottom: 1px solid #cdcdcd;
  }
  .detail-price {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid rgba(206, 42, 39, 1);
    text-align: left;
    font-weight: bold;
    color: rgba(206, 42, 39, 1);
  }
  .specificatii {
    margin-left: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
  }
  .buy {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem;
  }
  .buy > div {
    background-color: rgba(206, 42, 39, 1);
    padding: 0.5rem;
    border-radius: 1rem;
    color: white;
    font-weight: bold;
  }
  .alte-sugestii {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
  }
  .sugestii-card {
    object-fit: contain;
    width: 40%;
    height: 200px;
    margin: 0.5rem;
    box-shadow: 0.1rem 0.05rem 0px 0px rgba(0, 0, 0, 0.3);
    border: 2px solid rgba(206, 42, 39, 1);
    border-radius: 1rem;
    text-align: center;
  }
  .sugestii-image {
    object-fit: contain;

    width: 100%;
  }
  .sugestii-card > img {
    max-height: 100px;
    padding: 0.5rem;
  }
  .sugestii-title {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.6rem;
    font-weight: bold;
  }
  .sugestii-price {
    font-size: 0.8rem;
    font-weight: bold;
    color: rgba(206, 42, 39, 1);
    padding: 0.5rem;
  }
}

/*


*/
