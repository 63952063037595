.noresult-title {
  background-color: rgba(18, 26, 140, 0.5);
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
  text-align: center;
  border-bottom: 3px solid white;
  padding: 0.5rem;
}
.noresult-sad {
  text-align: center;
  font-size: 2rem;
  color: #ce2a27;
}
.noresult-desc {
  text-align: center;
  padding: 0.5rem;
}
