.formular-dimensiune-box {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ce2a27;
}
.formular-dimensiune-label {
  display: flex;
  justify-content: center;
}
.fromular-dimensiune-input {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}
.fromular-dimensiune-input > input {
  width: 25%;
}

.formular-data-input {
  /*  border: 2px solid #626262;
  border-radius: 5px; */
  border-bottom: 2px solid #ce2a27;
  display: flex;
}
.formular-data-input > span {
  font-size: 1rem;
  padding: 0.2rem;
  align-self: center;
}
.formular-data-input > input {
  padding: 0.2rem;
  text-align: right;
  flex: 1;
}
.question-box > div > input {
  padding: 0.2rem;
  border-bottom: 2px solid #ce2a27;
}
.fromular-dimensiune-input > input {
  padding-bottom: 0.2rem;
  border-bottom: 2px solid #ce2a27;
}
.janta-img {
  display: flex;
  justify-content: center;
  position: relative;
}
.janata-img > span {
  align-self: center;
}
.shape1 {
  display: inline-block;
  position: absolute;
  height: 0.2rem;
  width: 8rem;
  transform: rotate(45deg);
  top: 6.8rem;
  animation: pulse 1.2s infinite;
}

.shape2 {
  display: inline-block;
  position: absolute;
  transform: rotate(10deg);

  height: 0.2rem;
  width: 6.5rem;
  animation: pulse 1.2s infinite;
  top: 6.8rem;
}

@keyframes pulse {
  0% {
    background-color: #ce2a27;
  }
  49% {
    background-color: #ce2a27;
  }
  60% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: #ce2a27;
  }
}

.tractor-back {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  z-index: 0;
}

.tractor-dist1 {
  display: inline-block;
  position: absolute;
  height: 10rem;
  width: 7.5rem;
  top: 14rem;
  margin-right: 0.9rem;
  border: 3px solid;
  border-top: 0px;
  animation: pulse1 1.2s infinite;
}

.tractor-dist2 {
  display: inline-block;
  position: absolute;
  height: 10rem;
  width: 9.8rem;
  top: 14rem;
  margin-right: 0.9rem;
  border: 3px solid;
  border-top: 0px;
  animation: pulse1 1.2s infinite;
}

.tractor-dist3 {
  display: inline-block;
  position: absolute;
  height: 10rem;
  width: 12rem;
  top: 18rem;
  margin-right: 0.9rem;
  border: 3px solid;
  border-top: 0px;
  animation: pulse1 1.2s infinite;
}

.tractor-dist4 {
  display: inline-block;
  position: absolute;
  height: 18rem;
  width: 18rem;
  top: 10rem;
  margin-right: 0.9rem;
  border: 3px solid;
  border-top: 0px;
  animation: pulse1 1.2s infinite;
}

@keyframes pulse1 {
  0% {
    border-color: #ce2a27;
  }
  49% {
    border-color: #ce2a27;
  }
  60% {
    border-color: transparent;
  }
  99% {
    border-color: transparent;
  }
  100% {
    border-color: #ce2a27;
  }
}
.radiobutton-input {
  display: flex;
}
@media (max-width: 768px) {
  .radiobutton-input {
    display: flex;
    flex-direction: column;
  }
  .radiobutton-input label {
    width: 90%;
    display: inline-flex;
    margin: 0.5rem;
    border: 2px solid rgba(18, 26, 140, 1);
    font-weight: bold;
    color: #121a8c;
    border-radius: 8px;
    padding: 0.5rem;
  }
  .radio-image {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 769px) {
  .radiobutton-input label {
    display: inline-flex;
    margin: 0.5rem;
    border: 2px solid rgba(18, 26, 140, 1);
    font-weight: bold;
    color: #121a8c;
    border-radius: 8px;
    padding: 0.5rem;
    flex-direction: column-reverse;
  }
  .radiobutton-input {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
  }
}
.radiobutton-input input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.radiobutton-input input[type="checkbox"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radiobutton-input span {
  padding-top: 0.45rem;
  color: 121a8c;
}

.radiobutton-input label:hover {
  border-color: #77c3ec;
  color: #77c3ec;
}

.radiobutton-input input[type="radio"]:checked + label {
  background-color: rgba(206, 42, 39, 0.2);
  border: 2px solid #ce2a27;
  color: #ce2a27;
}
.radiobutton-input input[type="checkbox"]:checked + label {
  background-color: rgba(206, 42, 39, 0.2);
  border: 2px solid #ce2a27;
  color: #ce2a27;
}
.radio-button-circle {
  width: 1rem;
  height: 1rem;
  background-color: white;
  border: 2px solid rgba(18, 26, 140, 1);
  border-radius: 50%;
  margin: 0.5rem;
  display: flex;
  align-content: center;
  justify-content: center;
}
.radio-button-square {
  width: 1rem;
  height: 1rem;
  background-color: white;
  border: 2px solid rgba(18, 26, 140, 1);

  margin: 0.5rem;
  display: flex;
  align-content: center;
  justify-content: center;
}
.radiobutton-input label:hover .radio-button-circle {
  border-color: #77c3ec;
}
.radiobutton-input input[type="radio"]:checked + label .radio-button-circle {
  border-color: #ce2a27;
}
.radiobutton-input input[type="checkbox"]:checked + label .radio-button-circle {
  border-color: #ce2a27;
}
.radiobutton-input
  input[type="radio"]:checked
  + label
  > span
  > .radio-button-circle
  > div {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 100rem;
  background-color: #ce2a27;
  margin-top: 0.2rem;
}

.radiobutton-input input[type="checkbox"]:checked + label .radio-button-square {
  border-color: #ce2a27;
}
.radiobutton-input
  input[type="checkbox"]:checked
  + label
  > span
  > .radio-button-square
  > div {
  width: 0.6rem;
  height: 0.6rem;
  background-color: #ce2a27;
  margin-top: 0.2rem;
}
