.cos-icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 400;
  width: 100px;
}

.cos-detail-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 400;
  background-color: rgb(248 248 251);
  display: flex;
  flex-direction: column;
}
.cos-badge-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin: 0.5rem;
  background-color: white;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0.1rem 0.05rem 0px 0px rgba(0, 0, 0, 0.3);
}
.cos-badge {
  display: flex;
  flex-direction: row;
}
.cos-badge > .img {
  width: 60%;
}
.cos-badge > .description {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.cos-badge > .description > .title {
  font-size: 0.75rem;
  font-weight: bold;
  border-bottom: 2px solid #ce2a27;
}
.cos-badge > .description > .pret-table {
  font-size: 1rem;
  width: 100%;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}
.pret-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 0.75rem;
  justify-content: space-between;
}
.pret-line > .pret {
  color: #ce2a27;
}
.cos-badge > .cantitate {
  padding: 0.75rem;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 50%;
}
.cos-badge > .cantitate > div {
  padding-bottom: 0.5rem;
  text-align: center;
}
.cos-badge-container > .bottom-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cos-badge-container > .bottom-line > div {
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid #121a8c;
  color: #121a8c;
  border-radius: 50%;
}
.cos-alert {
  padding: 0.5rem;
  font-weight: bold;
  color: #ce2a27;
}
.cos-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cos-buttons > div {
  padding: 0.5rem;
  margin: 0.3rem;
  background: #0e2f56;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 1rem;
  font-weight: bolder;
}
.bottom-bar2 {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  background-color: rgb(248 248 251);
}

.cos-gol {
  display: flex;
  width: 100%;
  height: 75%;
  align-items: center;
  justify-content: center;
  color: #121a8c;
  font-size: 2rem;
  font-weight: bolder;
}
