.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 56px;
  width: 100%;
  background: rgb(248 248 251);
  z-index: 2;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar__logo a {
  color: #ce2a27;
  text-decoration: none;
  font-size: 1.5rem;
  margin: 2rem;
}

.spacer {
  flex: 1;
}

.toolbar__navigation-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.toolbar__navigation-items li {
  padding: 0 0.5rem;
}

.toolbar__navigation-items a {
  color: white;
  text-decoration: none;
}
.toolbar__navigation-items a:hover,
.toolbar__navigation-items a:active {
  color: black;
}

@media (max-width: 768px) {
  .toolbar__navigation-items {
    display: none;
  }
  .toolbar__logo > a > img {
    width: 30%;
    margin-top: 0.3rem;
  }
}
@media (min-width: 769px) {
  .toolbar__toggle-button {
    display: none;
  }
  .toolbar {
    border-bottom: 1px solid #a9a9a9;
  }
  .toolbar__logo > a > img {
    padding: 0rem;
    height: 2.5rem;
  }
}
