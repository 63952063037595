.tab {
  flex-grow: 1;
  text-align: center;
  padding: 0.5rem;
  margin: 0.2rem;
  border: 2px solid #121a8c;
  border-radius: 4px;
  color: #121a8c;
  font-weight: bold;
}

.tab-active {
  background-color: #121a8c;
  color: white;
}
.results-box {
  height: 1;
  overflow: scroll;
}
