.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.5s ease-out;
  overflow-y: scroll;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-left: 0;
}

.side-drawer li {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  padding-left: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #b1b1b1;
  display: flex;
}

.side-drawer a {
  color: #121a8c;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: bold;
}

.logo {
  width: 50%;
  padding: 1rem;
  padding-left: 2.5rem;
}
.logo > img {
  width: 100%;
}

.side-icon {
  width: 20%;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
}

.side-icon > img {
  width: 100%;
}

.side-icon2 {
  width: 17%;
  padding-right: 0.5rem;
}

.side-icon2 > img {
  width: 100%;
}
