.app-container {
  background-color: rgb(248 248 251);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  padding-top: 63px;
}
