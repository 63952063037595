.bottom-bar {
  padding: 0;
  margin: 0;
  list-style: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
  background: #ce2a27;
  display: flex;
  justify-content: center;
}

.bottom-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 4px white solid;
}
.bottom-button > div {
  color: #ffffff !important;
  font-size: 1.4rem;
  font-weight: bolder;
  text-decoration: none;
}
