.agro-numeric-input {
  text-align: center;
}
.agro-numeric-input > input {
  border: none;
  background-color: white;
  text-align: right;
  border-bottom: 2px solid rgba(18, 26, 140, 1);
  color: #121a8c;
  padding: 0.5rem;
  padding-right: 0rem;
  margin-right: 0;
  font-weight: bold;
  font-size: 1.3rem;
}
.agro-numeric-input > span {
  background-color: white;
  border-bottom: 2px solid rgba(18, 26, 140, 1);
  padding: 0.5rem;
  padding-left: 0.2rem;
  padding-top: 0.3rem;
  margin-left: 0;
  color: #121a8c;
  font-weight: bold;
  font-size: 1.4rem;
}
.radiobutton-input label {
  align-items: center;
  justify-content: space-between;
}
.radio-descriere {
  padding: 1rem;
}
