.menu-container {
  padding-top: 63px;
}
.menu-list-container > a {
  text-decoration: none;
}
.menu-list-item > .img-container {
  padding: 0;
  margin: 0;
  height: 50%;
}
.menu-list-item > .title {
  margin: 0.5vw;
  margin-left: 0;
  text-indent: 5%;

  font-weight: 600;
  color: black;
}
.menu-list-item > .button {
  bottom: 5px;
  text-indent: 5%;
  font-weight: bold;
  padding: 0.2vw;

  color: black;
}
.menu-list-item {
  display: flex;
  flex-direction: column;
  /*border: 3px solid rgba(18, 26, 140, 0.6);*/
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: white;
  box-shadow: 0.1rem 0.05rem 0px 0px rgba(0, 0, 0, 0.3);
}
.menu-list-item > ul {
  list-style: none;
  padding: 0;
  text-indent: 5%;
  color: #696969;
  font-weight: bold;

  height: 30%;
  overflow-y: scroll;
}
@media (min-width: 769px) {
  .menu-list-container {
    display: flex;
    overflow-y: scroll;
    scroll-behavior: smooth;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 1rem;
  }
  .menu-list-item > ul {
    font-size: 1rem;
    height: 30%;
  }
  .menu-container {
    overflow-y: scroll;
  }
  .desktop-image {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .desktop-image {
    width: 100%;
    height: 100%;
  }
  .menu-list-container > a {
    margin: 0.5rem;
    width: 25%;
    text-decoration: none;
  }
  .menu-list-container > a:hover {
    padding: 0;
    margin: 0;
  }
  .menu-title {
    display: none;
  }

  .menu-list-item > .title {
    font-size: 2rem;
  }
  .menu-list-item > .button {
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  .desktop-image {
    display: none;
  }
  .menu-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .menu-list-container {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding: 0.5vw;
    height: 60%;
    /*animation: slide infinite 20s reverse;*/
  }
  @keyframes slide {
    0% {
      padding-left: 0px;
      padding-right: 0px;
    }
    50% {
      padding-left: 500px;
      padding-right: -500px;
    }
    100% {
      padding-left: 1000px;
      padding-right: -1000px;
    }
  }
  .post-list:hover {
    animation-play-state: paused;
  }
  .menu-list-container > a {
    width: 70%;
    margin: 0.25rem;
    flex-shrink: 0;
    text-decoration: none;
    margin-bottom: 2rem;
  }
  .menu-title {
    font-size: 2rem;
    flex: 1;
    vertical-align: middle;
  }
  .menu-list-item > ul {
    font-size: 3vw;
    height: 30%;
  }
  .menu-list-item > .title {
    font-size: 5vw;
  }
  .menu-list-item > .button {
    padding: 0.2vw;
    font-size: 5vw;
  }
}
