.product-card {
  display: flex;
  background-color: white;
  border-radius: 0.5rem;
  margin: 0.5rem;
  overflow: hidden;
  box-shadow: 0.1rem 0.05rem 0px 0px rgba(0, 0, 0, 0.3);
  padding: 1rem;
}
.product-card-image {
  width: 50%;
}
.product-card-content {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}
.product-card-title {
  font-weight: bold;
  font-size: 1rem;
  /*color: #fcffff;
  background: #ce2a27;*/
}
.product-card-price {
  color: #ce2a27;
  font-weight: bolder;
  font-size: 0.8rem;
}
@media (max-width: 768px) {
  .product-card {
    height: 50%;
  }
}
@media (min-width: 769px) {
  .product-card {
    display: flex;
    margin: 1rem;
    flex-direction: column;
  }
}
