.dimensiune-input {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.dimensiune-input > input {
  border: 2px solid #121a8c;
  align-self: center;
  background-color: white;
  text-align: center;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #121a8c;
}

.dimensiune-input > ul {
  position: absolute;
  align-self: center;
  margin-top: 2.35rem;
  width: 80%;
  max-width: 231px;
  max-height: 250px;
  overflow: scroll;
  background-color: white;
  padding: 0;
  list-style: none;
  z-index: 200;
}
.dimensiune-input > ul > li {
  padding: 0.3rem;
  border-bottom: 1px solid #ce2a27;
  text-align: center;
}
.dimensiune-input > ul > li:hover {
  background-color: #ce2a27;
  color: white;
  font-weight: bold;
}
