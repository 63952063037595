.toggle-switch {
  display: inline-flex;
  border-radius: 3px;
  border: #ce2a27;
  border-style: solid;
  border-width: 3px;
  margin-right: 0.8rem;
}
.toggle-switch > div {
  display: inline-flex;
  font-size: 0.8rem;
}
.toggle-switch-active {
  background: #ce2a27;
  color: white;
}
.toggle-switch-inactive {
  background: white;
  color: #ce2a27;
}
