.results-list-container {
  position: absolute;
  right: 0;
  left: 0;
  overflow-y: scroll;
  bottom: 0;
  top: 125px;
}

@media (min-width: 679px) {
  .results-list-container > div {
    display: flex;
    flex-wrap: wrap;
  }

  .results-list-container > div > div {
    width: 25%;
  }
}
