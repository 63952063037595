.page {
  padding: 0.7rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page > .title {
  font-size: 1.5rem;
  text-align: justify;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #121a8c;
}

.page > .descriere {
  text-align: justify;
  padding-bottom: 0.5rem;
}

.skip-button {
  background-color: #f8f8fb;
  align-self: center;
  margin: 0.5rem;
  padding: 0.9rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 2px solid orange;
  color: orange;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 10px;
}
.skip-button:hover {
  background-color: orange;
  color: #f8f8fb;
}
.next-question {
  background-color: #f8f8fb;
  align-self: center;
  margin: 0.5rem;
  padding: 0.9rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #77c3ec;
  border: 2px solid #77c3ec;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 10px;
}
.prev-question {
  background-color: #f8f8fb;
  align-self: center;
  margin: 0.5rem;
  padding: 0.9rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #ce2a27;
  border: 2px solid #ce2a27;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 10px;
}

.prev-question:hover {
  background-color: #ce2a27;
  color: #f8f8fb;
}
.next-question:hover {
  background-color: #77c3ec;
  color: #f8f8fb;
}
.button-bar {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  padding: 0.5rem;
}
@media (max-width: 768px) {
  .button-bar {
    border-top: 1px solid #696969;
  }
}
@media (min-width: 769px) {
  .page {
    padding: 2rem;
  }
  .option-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .option-list > div {
    width: 33%;
    height: 25%;
    max-height: 25rem;
    max-width: 15rem;
  }
}
